// src/components/ChatHeader.tsx

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setAiProvider, setModel,setUseHistory,setSelectedPersona } from '../../store/slices/aiSlice';

import { getToken } from '../../service/authService';

const { REACT_APP_API_ENDPOINT } = process.env;


const ChatHeader: React.FC = () => {
  const dispatch = useDispatch();
  const aiProvider = useSelector((state: RootState) => state.ai.aiProvider);
  const model = useSelector((state: RootState) => state.ai.model);
  const useHistory = useSelector((state: RootState) => state.ai.useHistory);
  const selectedPersona = useSelector((state: RootState) => state.ai.selectedPersona);
  const [personas, setPersonas] = useState<{ id: number, title: string }[]>([]);

  const models = useSelector((state: RootState) => {
    switch (aiProvider) {
      case 'gpt':
        return ['gpt-4o','gpt-4o-mini','gpt-4-turbo', 'gpt-3.5-turbo-0125'];
      case 'ollama':
        return ['llama3.2','llama3.1','qwen2.5-coder','llama3','tinyllama','phi3:14b','gemma2', 'mixtral', 'codellama', 'codegemma','llama2:13b','llama3:70b','llava', 'brxce/stable-diffusion-prompt-generator'];
      case 'groq':
        return ['llama-3.1-70b-versatile','llama-3.2-3b-preview','llama-3.2-90b-vision-preview','llama3-8b-8192'];
      case 'claude':
        return ['claude-3-5-sonnet-20241022','claude-3-5-sonnet-20240620'];
      default:
        return [];
    }
  });

  useEffect(() => {
    if (models.length > 0 && !models.includes(model)) {
      dispatch(setModel(models[0]));
    }
  }, [aiProvider, dispatch, models, model]);

  useEffect(() => {
    const fetchPersonas = async () => {
      const token = getToken();
      const url = `${REACT_APP_API_ENDPOINT}/personas`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch personas');
        }

        const data = await response.json();
        setPersonas(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchPersonas();
  }, []);

  const handleAiProviderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setAiProvider(event.target.value));
  };

  const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setModel(event.target.value));
  };

  const handleUseHistoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
     dispatch(setUseHistory(event.target.checked));
  }

  const handlePersonaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSelectedPersona(Number(event.target.value)));
  };


  return (
    <div className="px-4 py-2 bg-gray-100 flex flex-col">
      <div className="p-3 flex items-center space-x-2">
        <div className="mr-8">
          <i className="bi bi-robot mr-2"></i> <b>Maria Rita</b>
        </div>
        <label htmlFor="aiProvider" className="text-sm font-medium text-gray-700">Escolher AI:</label>
        <select
            id="aiProvider"
            className="bg-white border border-gray-300 p-2 rounded"
            value={aiProvider}
            onChange={handleAiProviderChange}
        >
          <option value="ollama">Ollama</option>
          <option value="gpt">GPT</option>
          <option value="claude">Claude</option>
          <option value="groq">Groq</option>
        </select>
        <label htmlFor="model" className="text-sm font-medium text-gray-700">Modelos:</label>
        <select
            id="model"
            className="bg-white border border-gray-300 p-2 rounded"
            value={model}
            onChange={handleModelChange}
        >
          {models.map((model, index) => (
              <option key={index} value={model}>{model}</option>
          ))}
        </select>
        <label htmlFor="persona" className="text-sm font-medium text-gray-700">Persona:</label>
        <select
            id="persona"
            className="bg-white border border-gray-300 p-2 rounded"
            value={selectedPersona}
            onChange={handlePersonaChange}
        >
          <option value="">Selecione uma Persona</option>
          {personas.map((persona) => (
              <option key={persona.id} value={persona.id}>{persona.title}</option>
          ))}
        </select>
        <div className="flex items-center">
          <input id="useHistory" type="checkbox"
                 checked={useHistory}
                 onChange={handleUseHistoryChange}
                 className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label htmlFor="useHistory" className="ml-2 text-sm text-gray-700">Usar histórico</label>
        </div>
      </div>
      <hr className="border-t-2 border-gray-300 w-full p-1"/>
    </div>
  );
};

export default ChatHeader;
